<template>
  <v-sheet color="transparent" class="pb-6">

    <!-- Chats Table -->
    <ws-data-table
        :items="activeChats"
        :headers="headers"
        :search="search"
        :row-action="selectChat"
        no-header
        disable-pagination
        :selected-row="selectedChatId"
    >

      <template #item.name="{item , isSelected}">
        <v-sheet color="transparent" :dark="isSelected" class="d-flex">
          <v-avatar class="mr-4" size="40" :color="getChatAvatarColor(item.type)">
            <v-img transition="xxx" v-if="item.chat_img" :src="item.chat_img" />
            <v-icon v-else dark>{{ getChatIcon(item.type) }}</v-icon>
          </v-avatar>
          <div style="width: 100%" >
            <div class="d-flex justify-space-between">
              <ws-tooltip
                  v-if="getOldChatCondition(item.type) || false"
                  :text="$t('OldChatTooltip')">
                <h5>
                  <v-icon :color="wsATTENTION">mdi-close-octagon-outline</v-icon>
                  {{ item.name.length > 25 ?  (item.name.slice(0,25) + '...') : item.name }}
                </h5>
              </ws-tooltip>
              <h5 v-else> {{ item.name.length > 25 ?  (item.name.slice(0,25) + '...') : item.name }} </h5>
              <h5>{{  PARSE_TIME_STAMP(item.last_message_date , true , true) }} </h5>
            </div>
            <div class="d-flex justify-space-between">
              <h5 class="font-weight-light">{{ item.last_message_preview ? (item.last_message_preview.slice(0,35) + '...') : $t('NoMessages') }} </h5>
              <v-chip v-if="item.new_messages_count > 0" class="px-3" small dark :color="wsLIGHTACCENT"><h5>{{  item.new_messages_count }} </h5></v-chip>
            </div>

          </div>
        </v-sheet>

      </template>

    </ws-data-table>

  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "sidebarActiveChats",
  data() {
    return {
      headers : [
        { value : 'name' }
      ]
    }
  },
  computed : {
    ...mapState('messenger', [
      'activeChats',
      'search',
      'selectedChatId',
    ]),
  },
  methods : {
    ...mapActions('messenger', [ 'GET_CHATS' ] ),

    getOldChatCondition(type) {
      return !['contact_fine_business_support' , 'personal' , 'dynamic_chat' , 'chat']
          .includes(type) &&  (this.IS_BUSINESS_MANAGER || this.IS_BUSINESS_OWNER)
    },

    selectChat(item) {
      this.$store.state.messenger.selectedManager = {}
      this.$store.state.messenger.selectedManagerId = null
      this.$store.state.messenger.selectedChatId = item.uuid
      this.$store.state.messenger.selectedChat = item
    },

    getChatAvatarColor(type) {
      switch(type) {
        case 'chat' : return this.wsDARKLIGHT
        case 'dynamic_chat' : return this.wsDARK
        default : return this.wsLIGHTACCENT
      }
    },
    getChatIcon(type) {

      switch(type) {
        case 'chat' : return 'mdi-account-group'
        case 'dynamic_chat' : return 'mdi-bank'
        default : return 'mdi-account'
      }

    },

    async initPage() {

      let result = await this.GET_CHATS()

      if (!result || result === true ) { return  }

      result.forEach( (item,index) => {
        if ( item.last_message ) {
          result[index].last_message = item.last_message.length > 20 ? (item.last_message.slice(0,20) + '...') : item.last_message
        }

      })

      this.$store.state.messenger.activeChats = result

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>